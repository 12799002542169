WebFont.load({
    google: {
        families: ['EB+Garamond:400,400i,700,700i', 'Roboto:300,300i,400,400i,500,500i,700,700i&subset=latin-ext&display=swap']
    },
    custom: {
        families: ['FontAwesome'],
        urls: [
            'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css',
        ]
    }
});

(function ($) {
    function arbataxpark_refresh_size_queries() {
        var classes = [];
        var scrollbarwidth = arbataxpark_getScrollbarWidth();
        window_width = $(window).width() + scrollbarwidth;
        window_height = $(window).height();
        is_phone = (window_width < 768);
        is_mobile = (window_width < 992);
        is_tablet_portrait = (window_width >= 768 && window_width < 992);
        is_tablet_landscape = (window_width >= 992 && window_width < 1200 && window_height <= 768);
        is_tablet = is_tablet_portrait || is_tablet_landscape;
        is_desktop = (window_width >= 992);
        is_desktop_large = (window_width >= 1200);

        if (is_phone) {
            classes.push('mq_phone');
        }
        if (is_mobile) {
            classes.push('mq_mobile');
        }
        if (is_tablet_portrait) {
            classes.push('mq_tablet_portrait');
        }
        if (is_tablet_landscape) {
            classes.push('mq_tablet_landscape');
        }
        if (is_tablet) {
            classes.push('mq_tablet');
        }
        if (is_desktop) {
            classes.push('mq_desktop');
        }
        if (is_desktop_large) {
            classes.push('mq_desktop_large');
        }


        $('html').removeClass('mq_phone');
        $('html').removeClass('mq_mobile');
        $('html').removeClass('mq_tablet_portrait');
        $('html').removeClass('mq_tablet_landscape');
        $('html').removeClass('mq_tablet');
        $('html').removeClass('mq_desktop');

        $('html').addClass(classes.join(' '));

    }

    function arbataxpark_getScrollbarWidth() {
        var outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.width = "100px";
        outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
        document.body.appendChild(outer);
        var widthNoScroll = outer.offsetWidth;
        // force scrollbars
        outer.style.overflow = "scroll";
        // add innerdiv
        var inner = document.createElement("div");
        inner.style.width = "100%";
        outer.appendChild(inner);
        var widthWithScroll = inner.offsetWidth;
        // remove divs
        outer.parentNode.removeChild(outer);
        return widthNoScroll - widthWithScroll;
    }

    function arbataxpark_seo_position() {
        $('#seobar_wrapper').appendTo($('#seobar_container'));
    }

    function arbataxpark_animate_on_scroll() {
        AOS.init({
            duration: 1600,
            disable: function () {
                var maxWidth = 992;
                return window.innerWidth < maxWidth;
            }
        });
    }

    function arbataxpark_default_fancybox(){
        $('[data-fancybox]:not(.slick-cloned)').fancybox({
            buttons: [
                "close"
            ]
        });
    }

    function arbataxpark_header_position() {
        var header = $('#header_wrapper'),
            fixed = 'fixed',
            slideshow = $('#slideshow_container'),
            breakpoint = slideshow.height() - header.height(),
            scroll = $(window).scrollTop();

        if (slideshow.length > 0) {
            if (scroll >= breakpoint) {
                header.addClass(fixed);
            } else {
                header.removeClass(fixed);
            }
        } else {
            header.addClass(fixed);
        }
    }

    function arbataxpark_slideshow() {
        var wrap = $('.slideshow');
        if (wrap.length > 0) {
            wrap.on('lazyLoaded', function(event, slick, image, imageSource){
                var desktop_img  = image.attr('data-desktop'),
                    ipad_img  =  image.attr('data-ipad'),
                    phone_img = image.attr('data-phone');
                if ($(window).width() <= 414){
                    image.attr('src', phone_img);
                } else if ($(window).width() > 414 && $(window).width() < 991){
                    image.attr('src', ipad_img);
                } else {
                    image.attr('src', desktop_img);
                }
            });

            wrap.not('.slick-initialized').slick({
                rows: 0,
                fade: true,
                autoplay: true,
                arrows: true,
                dots: false,
                infinite: true,
                pauseOnHover: false,
                pauseOnFocus: false,
                autoplaySpeed: 4000,
                speed: 3000,
                waitForAnimate: false,
                dotsClass: 'slick-dots-inverse',
                lazyLoad: 'anticipated',
                prevArrow: '<a class="slick-arrow prev" href="javascript:;"></a>',
                nextArrow: '<a class="slick-arrow next" href="javascript:;"></a>',
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            dots: true,
                        }
                    }
                ]
            });

        }
    }

    function arbataxpark_responsive_slideshow(){
        var slide = $('.slideshow .slide_image');
        slide.each( function (){
            var desktop_img  = $(this).attr('data-desktop'),
                ipad_img  =  $(this).attr('data-ipad'),
                phone_img = $(this).attr('data-phone');
            if ($(window).width() <= 414){
                $(this).attr('src', phone_img);
            } else if ($(window).width() > 414 && $(window).width() < 991){
                $(this).attr('src', ipad_img);
            } else {
                $(this).attr('src', desktop_img);
            }
        });
    }

    function arbataxpark_video(){
        if(arbataxpark_has_video != 'no'){
            var player = new Plyr('#player', {
                volume:0.5
            });
            if(arbatax_autoplay_video === 'yes'){
                $('.slideshow').slick('slickPlay');
                $('#slideshow_container').addClass('playing_video');
                $('.player_wrapper').fadeIn('fast');
                player.muted = true;
                player.autoplay = true;
                player.volume = 0;
                $('.controls .volume i').attr('class', 'fas fa-volume-mute');
            }

            $('#play_video').on('click', function () {
                $('.slideshow').slick('slickPause');
                $('#slideshow_container').addClass('playing_video');
                $('.player_wrapper').fadeIn('fast');
                player.play();
                player.volume = 0.5;
            });

            player.on('ended', function(){
                $('#slideshow_container').removeClass('playing_video');
                $('.player_wrapper').fadeOut('fast');
                player.stop();
                $('.slideshow').slick('slickPlay');
            });

            $('.controls .player i').on('click', function () {
                if($(this).hasClass('fa-pause-circle')) {
                    $('.controls .player i').removeClass('fa-pause-circle');
                    $('.controls .player i').addClass('fa-play-circle');
                    player.pause();
                } else {
                    $('.controls .player i').removeClass('fa-play-circle');
                    $('.controls .player i').addClass('fa-pause-circle');
                    player.play();
                }
            });

            $('.volume').on('click', function () {
                var icon = $('.volume .fas');
                var up = 'fa-volume-up';
                var down = 'fa-volume-down';
                var mute = 'fa-volume-mute';

                if (icon.hasClass(down)) {
                    player.volume = 1;
                    icon.removeClass(down);
                    icon.addClass(up);
                } else if (icon.hasClass(up)) {
                    player.volume = 0;
                    icon.removeClass(up);
                    icon.addClass(mute);
                } else {
                    player.volume = 0.5;
                    icon.removeClass(mute);
                    icon.addClass(down);
                }
            });

            $('.controls .stop').on('click', function () {
                $('#slideshow_container').removeClass('playing_video');
                player.stop();
                $('.player_wrapper').fadeOut('fast');
                $('.slideshow').slick('slickPlay');
            });
        }
    }

    function arbataxpark_sidebarmenu() {
        var link = $('#open_menu'),
            wrap = $('#menu_sidebar_wrap'),
            close = wrap.find('.close_sidebar');
        if (link.length > 0 && wrap.length > 0) {
            link.click(function () {
                wrap.addClass('open');
                setTimeout(function () {
                    wrap.addClass('visible');
                }, 300);
                $('html').addClass('no_scroll');
            });

            close.click(function () {
                wrap.removeClass('visible');
                setTimeout(function () {
                    wrap.removeClass('open');
                }, 300);
                $('html').removeClass('no_scroll');
            });
        }
    }

    function arbataxpark_sidebarmenu_submenu() {
        $('<span class="more_li"><span class="fas fa-angle-right"></span></span>').appendTo($('#primary_menu .menu-item-has-children'));

        var button = $("#primary_menu .menu .more_li"),
            parent = $("li.menu-item-has-children"),
            submenu = $("ul.sub-menu"),
            mClass = "open",
            wrapper = $(".menu_sidebar");

        button.click(function () {
            if (!$(this).closest(parent).hasClass('open')) {
                parent.removeClass(mClass);
                parent.find(submenu).stop().fadeOut();

                $(this).closest(parent).find(submenu).stop().fadeIn(400,
                    function () {
                        $(this).closest(parent).addClass(mClass);
                    }
                );
            } else {
                $(this).closest(parent).find(submenu).stop().fadeOut(function () {
                    400,
                        $(this).closest(parent).removeClass(mClass);
                });
            }

        });
        parent.find('> a').click(function () {
            var link = $(this);
            if (link.attr('href') == 'javascript:;') {

                if (!link.closest(parent).hasClass('open')) {
                    parent.removeClass(mClass);
                    parent.find(submenu).stop().fadeOut();

                    $(this).closest(parent).find(submenu).stop().fadeIn(400,
                        function () {
                            $(this).closest(parent).addClass(mClass);
                        }
                    );
                } else {
                    link.closest(parent).find(submenu).stop().fadeOut(function () {
                        400,
                            $(this).closest(parent).removeClass(mClass);
                    });
                }

            }
        });
    }

    function arbataxpark_preview_rooms(){
        var rooms_wrapper = $('.rooms-carousel'),
        rooms = rooms_wrapper.children(),
        WW = window.innerWidth,
        activeCarousel = false;

        if(rooms_wrapper.length > 0){
            if(is_mobile && rooms.length > 0){
                activeCarousel = true;
                if(rooms.length === 2){
                    if(is_tablet_portrait || WW >= 712 && WW <= 768 ){
                        activeCarousel = false;
                        rooms_wrapper.addClass('no-carousel');
                    }
                }
            } else{
                if(rooms.length > 3){
                    activeCarousel = true;
                } else{
                    if(rooms.length === 3){
                        if(WW >=992 && WW <= 1220){
                            activeCarousel = true;
                        } else{
                            arbataxpark_carousel_resizing();
                        }
                    } else {
                        rooms_wrapper.addClass('no-carousel');
                    }
                }
            }

            if(activeCarousel){
                rooms_wrapper.on('init', function () {
                    lazyLoadInstance.update();
                });

                rooms_wrapper.not('.slick-initialized').slick({
                    rows: 0,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                    centerMode: true,
                    variableWidth: true,
                    responsive: [
                        {
                            breakpoint: 1220,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });
            }
        }
    }

    function arbataxpark_carousel_resizing(){

        var resizeTimer;

        $(window).on('resize', function(e) {

            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function() {
                // Run code here, resizing has "stopped"
                if(window.innerWidth >= 1220){
                    if($('.rooms-carousel').hasClass('slick-initialized')){
                        $('.rooms-carousel').slick('unslick');
                    }
                }

            }, 250);

        });
    }

    function arbataxpark_points_carousel(){
        var points_wrapper = $('.map-points-carousel');
        if(points_wrapper.length > 0){

            points_wrapper.on('init', function () {
                lazyLoadInstance.update();
            });
            points_wrapper.not('.slick-initialized').slick({
                rows: 0,
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: true,
                dots: true,
                centerMode: true,
                variableWidth: true,
                prevArrow: '<a class="slick-arrow black rounded prev" href="javascript:;"></a>',
                nextArrow: '<a class="slick-arrow black rounded next" href="javascript:;"></a>',
                appendArrows: '.arrows-container',
                responsive:[
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                        }
                    }
                ]
            });
        }
    }

    function arbataxpark_preview_pages_hp(){
        if($('html').hasClass('homepage')){
            var preview_pages_wrapper = $('.preview-pages-carousel');
            var children = preview_pages_wrapper.children();
            if(children.length > 0){
                preview_pages_wrapper.not('.slick-initalized').slick({
                    rows: 0,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                });
            }
        }
    }

    function arbataxpark_minigallery(){
        var minigallery_wrapper = $('.minigallery-carousel');
        var children = minigallery_wrapper.children();

        var activeCarousel = false;

        if(is_mobile && children.length > 0){
            activeCarousel = true;
        } else{
            if(children.length > 4){
                activeCarousel = true
            } else{
                minigallery_wrapper.addClass('no-carousel');
            }
        }

        if(activeCarousel){
            minigallery_wrapper.on('init', function(){
               lazyLoadInstance.update();
            });
            minigallery_wrapper.not('.slick-initalized').slick({
                rows: 0,
                dots: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                prevArrow: '<a class="slick-arrow prev" href="javascript:;"></a>',
                nextArrow: '<a class="slick-arrow next" href="javascript:;"></a>',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]


            });
        }
    }

    function arbataxpark_manual_fancybox() {
        $('a[data-manual-fancy]').on('click',function () {
            var button = $(this);
            var data = button.data('src');
            if (!data) {
                return;
            }
            var html = $(data).text();
            $.fancybox.open(html, {
                'afterShow': function () {
                    if (typeof renderRecaptcha !== 'undefined') {
                        renderRecaptcha();
                    }
                    if (typeof gformInitDatepicker !== 'undefined') {
                        gformInitDatepicker();
                    }
                },
                autoFocus: false,
                arrows: false,
                infobar: false
            })
        })
    }


    function arbataxpark_web_sdk_offers() {
        //This variables are for highlight special offers websdk
        window.ArbataxallOffers = window.ArbataxallOffers || {};
        window.ArbataxwidgetstoLoad = $('div[data-websdk]').length;
        window.ArbataxwidgetsLoaded = 0;
        window.AllwidgetLoaded = false;

        $fbsdk.Config.baseHost = 'websdk.fastbooking-services.com';
        $('div[data-websdk]').each(function () {
            var container = $(this),
                model_name = container.data('websdk'),
                id = container.attr('id'),
                custom_template = container.data('websdk-template'),
                template_id, config, model, template, html;
            template_id = custom_template ? custom_template : '#' + id + '_template';
            template = $(template_id).text();
            if (typeof websdk_config !== 'undefined' && typeof websdk_config[id] !== 'undefined' /*&& typeof $fbsdk !== "undefined"*/) {
                config = websdk_config[id];
                $fbsdk.baseHost = config.baseHost;
                model = new $fbsdk[model_name](config.params);
                model.setAuthToken(config._authCode);
                model.load(function (error) {
                    var data, i;
                    if (!error) {
                        //YEAH, this happens when we have data and we're ready to use it!
                        data = this.getRawData();
                        if (model_name == 'Offers' && data.rates.length == 0) {
                            container.addClass('no_offers');
                            container.closest('.special_offers_row').find('.hotel .price').remove();
                        }
                        container.closest('.loading').addClass(' processed noloading');

                        if (typeof data.rates !== 'undefined') {

                            if (typeof config.excluded_offers !== 'undefined' && config.excluded_offers !== '') {
                                var temp_rates = [];
                                for (var i = 0; i < data.rates.length; i++) {
                                    if (typeof data.rates[i] !== 'undefined') {
                                        if ($.inArray(data.rates[i].rate.name, config.excluded_offers) == -1) {
                                            // if is allowed
                                            temp_rates.push(data.rates[i]);
                                        }
                                    }
                                }
                                // OVERRRIDE THE OBJ
                                data.rates = temp_rates;
                            }

                            if (typeof config['putBestPriceIn'] !== 'undefined') {
                                var bestprice = false;
                                for (var i = 0; i < data.rates.length; i++) {
                                    if (bestprice === false || data.rates[i].quotation.totalPrice < bestprice) {
                                        bestprice = data.rates[i].quotation.totalPrice;
                                    }
                                }
                                if (bestprice) {
                                    container.closest(config['putBestPriceIn'].closest).find(config['putBestPriceIn'].find).html(bestprice);
                                }
                            }
                            if (typeof config.number !== 'undefined' && config.number <= data.rates.length) {
                                data.rates.length = parseInt(config.number, 10);
                            }

                            for (var i = 0; i < data.rates.length; i++) {
                                //This variables are for highlight special offers websdk
                                var rate = data.rates[i],
                                    rate_name = rate.rate.name.toLowerCase(),
                                    numeric_first_date = parseInt(rate.firstAvailDate.bookingParams.fromYYMMDD,10);

                                //Find the discount foreach offer
                                var diff_prices = data.rates[i].quotation.totalPriceNonDiscounted - data.rates[i].quotation.totalPrice;
                                var discount_price  = (diff_prices/data.rates[i].quotation.totalPriceNonDiscounted) * 100;

                                data.rates[i].rate.discountrate = '-' + Math.round(discount_price) + '%';

                                window.ArbataxallOffers[rate_name] = window.ArbataxallOffers[rate_name] ||
                                    {
                                        rate:$.extend(true,{},rate),
                                        bestprice:rate.quotation.totalPrice,
                                        hotels:[],
                                        first_date:numeric_first_date,
                                        firstAvailDate:$.extend(true,{},rate.firstAvailDate),
                                    };
                                window.ArbataxallOffers[rate_name].hotels.push(data.prop.property);
                                // We want all access codes, no matter if duplicated
                                window.ArbataxallOffers[rate_name].rate.rate.distribution.accessCode = window.ArbataxallOffers[rate_name].rate.rate.distribution.accessCode.concat(rate.rate.distribution.accessCode);


                                //If total price is minor of best price for highlight special offers websdk
                                if(rate.quotation.totalPrice < window.ArbataxallOffers[rate_name].bestprice){
                                    window.ArbataxallOffers[rate_name].bestprice = rate.quotation.totalPrice;
                                }

                                //If numeric first date is minor of first date for highlight special offers websdk
                                if(numeric_first_date < window.ArbataxallOffers[rate_name].first_date){
                                    window.ArbataxallOffers[rate_name].first_date = numeric_first_date;
                                    window.ArbataxallOffers[rate_name].firstAvailDate = rate.firstAvailDate;
                                }

                                if (i % 2 == 0) {
                                    data.rates[i].rate.classname = 'even';
                                } else {
                                    data.rates[i].rate.classname = 'odd';
                                }
                                data.rates[i].rate.shortdescription = data.rates[i].rate.plain_description.substr(0, 120) + '...';
                            }
                        }

                        html = Mustache.render(template, data);
                        if(typeof config.item_classes !== 'undefined'){
                            var dom = $('<div/>').append(html);
                            dom.find('.offer_wrap').each(function(){
                                $(this).addClass(config.item_classes);
                            });
                            html = dom.html();
                        }

                        container.html(html);

                        //Added the MCM offers before WEBSDK offers only special offers page
                        if($('html').hasClass('special-offers-page')){
                            var  mcm_offers = $('.mcm-offers-container').html(),
                            mcm_offers_itm = $('.mcm-offers-container').children();
                            if(mcm_offers_itm.length > 0){
                                $(mcm_offers).prependTo(container);
                            }
                        }

                        //Update lazyload images
                        lazyLoadInstance.update();

                        //da qui in poi ho  HTML delle offerte nella pagina
                        var offers = container.find('.offer_wrap');
                        var delay = 0;
                        var activeCarousel = false;
                        offers.each(function (el, i) {
                            var offer = $(this);
                            var description = offer.find('.offer_details');

                            offer.find('.thumb_wrap, .info, .title').on('click', function () {
                                $().fancybox({
                                    content: description
                                });
                            });

                            //Preview offers carousel
                            if ($('.preview-offers').length > 0) {
                                //Add delay to single hotel page websdk
                                delay += 200;
                                $(this).find('.item_wrap').attr('data-aos-delay', delay);
                                if(el > 2){
                                    $('#FB_so_preview_offers').removeClass('no-carousel');
                                    activeCarousel = true;
                                } else {
                                    $('#FB_so_preview_offers').addClass('no-carousel');
                                    if(is_mobile && el > 0){
                                        activeCarousel = true;
                                    }
                                }
                                if(activeCarousel){
                                    arbataxpark_preview_offers_carousel();
                                }
                            }
                        });


                        //Counting hotels and add item
                        window.ArbataxwidgetsLoaded++;
                        if(window.ArbataxwidgetstoLoad == window.ArbataxwidgetsLoaded && !window.AllwidgetLoaded){
                            window.AllwidgetLoaded = true;
                            $(document).trigger('arbataxpark_widgets_loaded');
                        }
                    } else {
                        // in caso di errore
                        container.fadeOut();
                    }
                });
            }
        });

        //If any widget group to load trigger event after 15 seconds
        setTimeout(function(){
            if(!window.AllwidgetLoaded){
                window.AllwidgetLoaded = true;
                $(document).trigger('arbataxpark_widgets_loaded');
            }
        },5000);
    }

    function arbataxpark_preview_offers_carousel() {
        var wrapper = $('#FB_so_preview_offers');
        var children = wrapper.children();
        var activeCarousel = false;
        if(children.length > 3){
            $('#FB_so_preview_offers').removeClass('no-carousel');
            activeCarousel = true;
        } else {
            $('#FB_so_preview_offers').addClass('no-carousel');
            if(is_mobile && children.length > 0){
                activeCarousel = true;
            }
        }
        if(activeCarousel){
            wrapper.on('init', function(){
                lazyLoadInstance.update();
            });
            wrapper.not('.slick-initialized').slick({
                rows: 0,
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            variableWidth: false,
                            arrows: false,
                            dots: true,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        }
    }

    function arbataxpark_special_offers(){
        if($('html').hasClass('special-offers-page')){
            $(".single-tab:first").show();
            if(is_desktop){
                $("ul.tabs li:first").addClass("active").show();

                $("ul.tabs li").click(function() {
                    $("ul.tabs li").removeClass("active");
                    $(this).addClass("active");
                    $(".single-tab").hide();
                    var activeTab = $(this).find("a").attr("href");
                    $(activeTab).fadeIn();
                    return false;
                });
            }
            if(is_mobile){
                $('.filters-special-offers').change(function() {
                    $(".single-tab").hide();
                    $('#' + $(this).val()).show();
                });
            }
        }
    }

    function arbataxpark_mcm_only_for_guest_account(){
        if (localStorage.getItem("profile") !== null) {
            if($('html').hasClass('special-offers-page')){
                // var fbga_data = JSON.parse(localStorage.profile);
                // if(fbga_data.identities[0].connection === 'facebook'){
                setInterval(function(){
                    $('.only_for_ga').show();
                },1000);
                // }
            }
        }
    }

    function arbataxpark_surroundings(){
        var surroundings_carousel = $('.surroundings-carousel');
        surroundings_carousel.each(function(){
            $(this).on('init', function(){
                lazyLoadInstance.update();
            });
            $(this).not('.slick-initialized').slick({
                rows: 0,
                infinite: true,
                variableWidth: true,
                arrows: true,
                dots: false,
                prevArrow: '<a class="slick-arrow black rounded prev" href="javascript:;"></a>',
                nextArrow: '<a class="slick-arrow black rounded next" href="javascript:;"></a>',
                appendArrows: $(this).parent().parent().find('.arrows-container'),
                responsive: [
                    {
                        breakpoint: 550,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            variableWidth: false,
                            arrows: false,
                            dots: true,
                            appendDots: $(this).parent().find('.pager'),
                        }
                    }
                ]
            });
        });

        $('.coast-children .item_wrap, .culture-children .item_wrap').each(function(index, el){
            var desc = $(this).find('.description').text().trim().length;
            if(is_phone){
                var limit_desc = 115;
            } else{
                var limit_desc = 250;
            }

            if(desc > limit_desc){
                $(this).find('.description').addClass('has_scroll single_scroll_'+ index);
                $('.single_scroll_'+ index).mCustomScrollbar();
            }
        });
    }

    function arbataxpark_map_sidebarHeight() {
        var sidebar = $('.content-with-sidebar .sidebar_wrapper');
        if (sidebar.length > 0 && !is_mobile) {
            var wh = $(window).height(),
                height = wh - $('#header_wrapper').height();

            sidebar.css({
                height: height
            });
            sidebar.parent().find('.content-wrapper').css({
                'min-height': height
            });
            $(window).scroll(function () {
                var bt = $('#footer_menu').offset().top,
                    bottom = (Math.floor(bt - $(window).scrollTop() - wh)) * -1;
                if (bottom >= 0) {
                    sidebar.css({
                        bottom: bottom
                    })
                } else {
                    sidebar.css({
                        bottom: 0
                    })
                }
            })
        }
    }

    function arbataxpark_location_map(div, config){
        var overlays = {};

        if (typeof (div) === "undefined") div = "map_canvas";
        if (typeof (bounds) === "undefined" && map_data.set_bounds) bounds = new google.maps.LatLngBounds();

        var marker, i;

        var infoWindow = new google.maps.InfoWindow({
            'maxWidth': 500,
            'maxHeight': 400,
            'autoScroll': true
        });

        var lat = parseFloat(map_data.markers[0].latitude);
        var lng = parseFloat(map_data.markers[0].longitude);

        center = {lat:+lat, lng:+lng};

        map = new google.maps.Map(document.getElementById(div), {
            // scroll wheel
            scrollwheel: false,
            //zoom
            zoom: map_data.map_zoom,
            zoomControl: map_data.map_zoom_control,
            zoomControlOptions: {
                position: google.maps.ControlPosition[map_data.zoom_control_position]
            },
            //position
            center: new google.maps.LatLng(center[0], center[1]),
            //map type
            mapTypeId: google.maps.MapTypeId[map_data.map_type],
            mapTypeControl: map_data.map_type_control,
            mapTypeControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT,
                style: google.maps.MapTypeControlStyle.DEFAULT
            },
            // default pan control
            panControl: false,
            panControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP
            },
            // scale control - image with the scale index (m,km,etc)
            scaleControl: false,
            scaleControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            },
            // 	streetview controls
            streetViewControl: map_data.map_streetview_control,
            streetViewControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            }

        });

        var styles = [{
            "featureType": "poi",
            "elementType": "all",
            "stylers": [{"visibility": "off"}]
        }, {"featureType": "poi", "elementType": "geometry", "stylers": [{"visibility": "off"}]}, {
            "featureType": "poi",
            "elementType": "labels",
            "stylers": [{"visibility": "off"}]
        }];

        map.setOptions({styles: styles});

        for (i = 0; i < map_data.markers.length; i++) {
            if(map_data.markers[i].slug === 'group'){
                var icon = {
                    url: map_data.markers[i].icon_hotel,
                    scaledSize: new google.maps.Size(30,50),
                }
            } else {
                var icon = {
                    url: map_data.markers[i].icon_hotel,
                    scaledSize: new google.maps.Size(40,60),
                }
            }

            marker = new google.maps.Marker({
                position: new google.maps.LatLng(map_data.markers[i].latitude, map_data.markers[i].longitude),
                map: map,
                icon: icon,
                optimized: false,
                zIndex: 99999999
            });

            markers.push(marker);

            bounds.extend(marker.position);


            google.maps.event.addListener(marker, 'click', (function (marker, i) {
                return function () {
                    infoWindow.setContent(map_data.markers[i].html);
                    map.setCenter(marker.getPosition());
                    infoWindow.open(map, marker);
                };
            })(marker, i));

            google.maps.event.addListener(infoWindow, 'closeclick', function () {
                map.fitBounds(bounds);
            });
        }

        if (map_data.set_bounds) {
            map.fitBounds(bounds);
        } else {
            map.setCenter(center);
        }

        if (typeof config.map_overlay !== 'undefined') {
            for (key in config.overlays) {
                var overlayData = config.overlays[key];
                var swBound = new google.maps.LatLng(overlayData.coords.sw.latitude, overlayData.coords.sw.longitude);
                var neBound = new google.maps.LatLng(overlayData.coords.ne.latitude, overlayData.coords.ne.longitude);
                var bounds_overlay = new google.maps.LatLngBounds(swBound, neBound);
                overlays[key] = new PictureOverlay(bounds_overlay, overlayData.url, map, overlayData.display);
            }
        }

        setTimeout(function(){
            overlays.general.setDisplay('block');
        },1000);
    }

    function arbataxpark_social_media(){
        var sociaL_media_text = $('.social-media-text');
        var social_media_container = $('.social-media-container');
        var single_thumb = social_media_container.find('.single-image');
        var totalWidth = 0;

        social_media_container.children().each(function(index, el){
            if(index <= 1){
                totalWidth += $(this).outerWidth();
            }
        });

        single_thumb.css({
            'height': single_thumb.width()
        });

        if(is_desktop){
            if(sociaL_media_text.length > 0){
                sociaL_media_text.insertAfter('.social-media-container .single-image-wrap:eq(0)');
            }
        }
    }

    function arbataxpark_countdown_format(){
        $('[data-countdown]').each(function () {
            var $this = $(this), finalDate = $(this).data('countdown');
            $this.countdown(finalDate, function (event) {
                $this.html('<div class="item"><div class="label">' + popup_counter_label_days + '</div><div class="value">' + event.strftime('%D') + '</div></div><div class="item"><div class="label">' + popup_counter_label_hours + '</div><div class="value">' + event.strftime('%H') + '</div></div><div class="item"><div class="label">' + popup_counter_label_minutes + '</div><div class="value">' + event.strftime('%M') + '</div></div><div class="item"><div class="label">' + popup_counter_label_seconds + '</div><div class="value">' + event.strftime('%S') + '</div></div>');
                //This check is only for the popup
                if($('html').hasClass('homepage')){
                    if(event.type === 'finish'){
                        $('#popup_wrapper .fancybox-close-small').trigger('click');
                    }
                }
            });
        });
    }

    function arbataxpark_popup() {
        if ($('html').hasClass('homepage')) {
            if ($('#popup_wrapper').length > 0) {
                $.fancybox.open({
                    src: '#popup_wrapper',
                    type: 'inline',
                    touch: false,
                    autoFocus: false,
                    afterLoad: function () {
                        lazyLoadInstance.update();
                    }
                });
            }
        }
    }

    function arbataxpark_backtoTop() {
        var btn = $('.bottom_bnt'),
            custom = 'show',
            breakpoint = $(window).height() / 2,
            scroll = $(window).scrollTop();

        if (btn.length > 0) {
            if (scroll > breakpoint) {
                btn.addClass(custom);
            } else {
                btn.removeClass(custom);
            }
            btn.click(function () {
                if ($(this).hasClass('return_top')) {
                    $('html,body').stop().animate({scrollTop: 0}, 500);
                }
            });
        }
    }

    function arbataxpark_goDown(){
        var go_btn = $('.go_down_bnt');

        go_btn.click(function(){
            $('html, body').animate({
                scrollTop: $('.custom_anchor').offset().top
            }, 1500);
        });

        $(window).scroll(function () {
            if ($(window).scrollTop() > 0) {
                go_btn.removeClass('show');
            } else {
                go_btn.addClass('show');
            }
        });
    }

    //Load Window
    $(window).on('load', function () {
        arbataxpark_refresh_size_queries();
        arbataxpark_animate_on_scroll();
    });

    //Resize Window
    $(window).resize(function () {
        arbataxpark_refresh_size_queries();
        arbataxpark_responsive_slideshow();
        arbataxpark_social_media();
        arbataxpark_preview_rooms();
    });

    //Dom ready
    $(document).ready(function () {
        window.lazyLoadInstance = new LazyLoad({
            elements_selector: ".lazy"
        });
        arbataxpark_refresh_size_queries();
        arbataxpark_seo_position();
        arbataxpark_header_position();
        arbataxpark_default_fancybox();
        arbataxpark_backtoTop();
        arbataxpark_goDown();
        window.onscroll = function () {
            arbataxpark_header_position();
            arbataxpark_backtoTop();
        };
        arbataxpark_slideshow();
        arbataxpark_responsive_slideshow();
        arbataxpark_video();
        arbataxpark_sidebarmenu();
        arbataxpark_sidebarmenu_submenu();
        arbataxpark_preview_rooms();
        arbataxpark_points_carousel();
        arbataxpark_preview_pages_hp();
        arbataxpark_minigallery();
        arbataxpark_manual_fancybox();
        arbataxpark_web_sdk_offers();
        arbataxpark_social_media();
        arbataxpark_popup();
        arbataxpark_countdown_format();
        arbataxpark_preview_offers_carousel();
        arbataxpark_special_offers();
        arbataxpark_mcm_only_for_guest_account();
        arbataxpark_surroundings();
        arbataxpark_openFbMobileApp();
        if ($('html').hasClass('services-page')) {
            arbataxpark_map_sidebarHeight();
            initialize_map_by_config();
            filter_btns_by_area_type();
            filter_map_by_category();
            if(hotel_data['hotel_hid'] == 'itsar24726'){
                load_markers_by('category', 'suites');
            } else if(hotel_data['hotel_hid'] == 'itsar24720' || hotel_data['hotel_hid'] == 'itsar24722' || hotel_data['hotel_hid'] == 'itsar24723'){
                load_markers_by('area', 'telis');
            }
        }
        if($('html').hasClass('location-page')){
            arbataxpark_location_map('map_canvas',resort_map_config);
        }

        //chat link on footer
        $('.element_whatsapp #chat').on( "click", function() {
            $('#botTitleBar').trigger( "click" );
        });

        //This is an hack for from chrome mobile because the browser not render some D-EDGE custom icon
        if(is_mobile){
            setTimeout(function(){
                $('.services-section .services-list .single-service .icon').each(function(){
                    $(this).css({'font-size':59});

                });
            }, 3000);
        }
    });
})(jQuery);

/**
 * JavaScript Client Detection
 */
(function (window) {
    {
        var unknown = '-';

        // screen
        var screenSize = '';
        if (screen.width) {
            width = (screen.width) ? screen.width : '';
            height = (screen.height) ? screen.height : '';
            screenSize += '' + width + " x " + height;
        }

        // browser
        var nVer = navigator.appVersion;
        var nAgt = navigator.userAgent;
        var browser = navigator.appName;
        var version = '' + parseFloat(navigator.appVersion);
        var majorVersion = parseInt(navigator.appVersion, 10);
        var nameOffset, verOffset, ix;

        // Opera
        if ((verOffset = nAgt.indexOf('Opera')) != -1) {
            browser = 'Opera';
            version = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf('Version')) != -1) {
                version = nAgt.substring(verOffset + 8);
            }
        }
        // Opera Next
        if ((verOffset = nAgt.indexOf('OPR')) != -1) {
            browser = 'Opera';
            version = nAgt.substring(verOffset + 4);
        }
        // Legacy Edge
        else if ((verOffset = nAgt.indexOf('Edge')) != -1) {
            browser = 'Microsoft Legacy Edge';
            version = nAgt.substring(verOffset + 5);
        }
        // Edge (Chromium)
        else if ((verOffset = nAgt.indexOf('Edg')) != -1) {
            browser = 'Microsoft Edge';
            version = nAgt.substring(verOffset + 4);
        }
        // MSIE
        else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
            browser = 'Microsoft Internet Explorer';
            version = nAgt.substring(verOffset + 5);
        }
        // Chrome
        else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
            browser = 'Chrome';
            version = nAgt.substring(verOffset + 7);
        }
        // Safari
        else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
            browser = 'Safari';
            version = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf('Version')) != -1) {
                version = nAgt.substring(verOffset + 8);
            }
        }
        // Firefox
        else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
            browser = 'Firefox';
            version = nAgt.substring(verOffset + 8);
        }
        // MSIE 11+
        else if (nAgt.indexOf('Trident/') != -1) {
            browser = 'Microsoft Internet Explorer';
            version = nAgt.substring(nAgt.indexOf('rv:') + 3);
        }
        // Other browsers
        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
            browser = nAgt.substring(nameOffset, verOffset);
            version = nAgt.substring(verOffset + 1);
            if (browser.toLowerCase() == browser.toUpperCase()) {
                browser = navigator.appName;
            }
        }
        // trim the version string
        if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix);
        if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix);
        if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix);

        majorVersion = parseInt('' + version, 10);
        if (isNaN(majorVersion)) {
            version = '' + parseFloat(navigator.appVersion);
            majorVersion = parseInt(navigator.appVersion, 10);
        }

        // mobile version
        var mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

        // cookie
        var cookieEnabled = (navigator.cookieEnabled) ? true : false;

        if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
            document.cookie = 'testcookie';
            cookieEnabled = (document.cookie.indexOf('testcookie') != -1) ? true : false;
        }

        // system
        var os = unknown;
        var clientStrings = [
            {s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/},
            {s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/},
            {s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/},
            {s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/},
            {s: 'Windows Vista', r: /Windows NT 6.0/},
            {s: 'Windows Server 2003', r: /Windows NT 5.2/},
            {s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/},
            {s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/},
            {s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/},
            {s: 'Windows 98', r: /(Windows 98|Win98)/},
            {s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/},
            {s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/},
            {s: 'Windows CE', r: /Windows CE/},
            {s: 'Windows 3.11', r: /Win16/},
            {s: 'Android', r: /Android/},
            {s: 'Open BSD', r: /OpenBSD/},
            {s: 'Sun OS', r: /SunOS/},
            {s: 'Chrome OS', r: /CrOS/},
            {s: 'Linux', r: /(Linux|X11(?!.*CrOS))/},
            {s: 'iOS', r: /(iPhone|iPad|iPod)/},
            {s: 'Mac OS X', r: /Mac OS X/},
            {s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/},
            {s: 'QNX', r: /QNX/},
            {s: 'UNIX', r: /UNIX/},
            {s: 'BeOS', r: /BeOS/},
            {s: 'OS/2', r: /OS\/2/},
            {s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/}
        ];
        for (var id in clientStrings) {
            var cs = clientStrings[id];
            if (cs.r.test(nAgt)) {
                os = cs.s;
                break;
            }
        }

        var osVersion = unknown;

        if (/Windows/.test(os)) {
            osVersion = /Windows (.*)/.exec(os)[1];
            os = 'Windows';
        }

        switch (os) {
            case 'Mac OS':
            case 'Mac OS X':
            case 'Android':
                osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(nAgt)[1];
                break;

            case 'iOS':
                osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
                osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
                break;
        }

        // flash (you'll need to include swfobject)
        /* script src="//ajax.googleapis.com/ajax/libs/swfobject/2.2/swfobject.js" */
        var flashVersion = 'no check';
        if (typeof swfobject != 'undefined') {
            var fv = swfobject.getFlashPlayerVersion();
            if (fv.major > 0) {
                flashVersion = fv.major + '.' + fv.minor + ' r' + fv.release;
            } else {
                flashVersion = unknown;
            }
        }
    }

    window.jscd = {
        screen: screenSize,
        browser: browser,
        browserVersion: version,
        browserMajorVersion: majorVersion,
        mobile: mobile,
        os: os,
        osVersion: osVersion,
        cookies: cookieEnabled,
        flashVersion: flashVersion
    };
}(this));

function arbataxpark_openFbMobileApp(e) {
    var fbSelector = jQuery(".socials").find("a.facebook");
    if (jscd.os === 'iOS') {
        jQuery(fbSelector).on('click', function () {
            window.open('fb://page/?id=117540845016990', true);
        });
    } else if (jscd.os === 'Android') {
        jQuery(fbSelector).on('click', function () {
            window.open('intent://page/117540845016990?referrer=app_link#Intent;package=com.facebook.katana;scheme=fb;end', true);
        });
    }
}